import { orderStoreKey } from '@/keys';
import { OrderDetails, OrderStatus, OrderStoreState, ResponseError, PaidOrder } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { OrderStatusState } from '@/enums';
import { isResponseError } from '../functions';
import axios from "axios";
import {useLocalStorage} from "@vueuse/core";


export const OrderStore = defineStore(orderStoreKey, () => {

    /* State */
    // const state = ref<OrderStoreState>({
    //     details: null,
    //     paidOrders: [],
    //     deliveredOrders: []
    // });
    const state = useLocalStorage(orderStoreKey,
      {
          details: null,
          paidOrders: [],
          deliveredOrders: []
      } as OrderStoreState);

    state.value.paidOrders.forEach(x => {
        //If expires is a string, convert it to Date
        if (typeof x.expires === 'string') {
            x.expires = new Date(x.expires);
        }
    })

    state.value.deliveredOrders.forEach(x => {
        //If expires is a string, convert it to Date
        if (typeof x.expires === 'string') {
            x.expires = new Date(x.expires);
        }
    })
    
    
    const pullInterval = 600000; // 1 minute;
    let pullIntervalKey: number | undefined;

    
    /*
    const loadFromStorage = (e: StorageEvent | undefined | null) => {
        console.log('orderStore.loadFromStorage', e);
        //If event was thrown by irrelevant storage
        if (e && e.key !== orderStoreKey) {
            return;
        }

        const storedState = useLocalStorage(orderStoreKey, state.value);
        //const orderJson: string | null = localStorage.getItem(orderStoreKey);
        //if (orderJson) {
            try {
                state.value = storedState.value //JSON.parse(orderJson) as OrderStoreState;

                state.value.paidOrders.forEach(x => {
                    //If expires is a string, convert it to Date
                    if (typeof x.expires === 'string') {
                        x.expires = new Date(x.expires);
                    }
                })

                state.value.deliveredOrders.forEach(x => {
                    //If expires is a string, convert it to Date
                    if (typeof x.expires === 'string') {
                        x.expires = new Date(x.expires);
                    }
                })

            }
            catch (ex) {
                console.warn('orderStore data was corrupt', ex);
                //If json is corrupt, remove data.
                localStorage.removeItem(orderStoreKey);
            }
        }
    //}
*/
    const id = computed<string | null>(() => {
        if (state.value?.details) {
            return state.value.details.orderId
        }
        return null;
    });

    const ordersCount = computed<number>(() => {
        const result =  state.value.deliveredOrders.length + state.value.paidOrders.length;
        // console.log('orderStore.ordersCount', result);
        return result;
    });

    const save = () => {
        //console.log('orderStore.save', state.value);
        //localStorage.setItem(orderStoreKey, JSON.stringify(state.value));
    }

    const set = (item: OrderDetails) => {
        state.value.details = item;
        state.value.details.userAgent = window.navigator.userAgent;
        //Store items in local storage.
        //localStorage.setItem(orderStoreKey, JSON.stringify(state.value));
    }

    const addPaymentID = (id: string | null) => {
        if (state.value.details && id) {
            state.value.details.paymentID = id;
        }
    }

    const clear = (clearStore: boolean | undefined) => {

        if (clearStore) {
            state.value.details = null;
            state.value.paidOrders = [];
            state.value.deliveredOrders = [];
        }

        clearInterval(pullIntervalKey);
        pullIntervalKey = undefined;
        localStorage.removeItem(orderStoreKey);
    }


    const cancelPullOrderStatus = () => {
        clearInterval(pullIntervalKey);
    }

    const pullOrderStatus = () => {
        if (!pullIntervalKey) {
            getOrderStatus();
            pullIntervalKey = setInterval(getOrderStatus, pullInterval);
        }
    };


    //Remove all done paid orders after they expire
    const removeExpiredOrders = () => {

        const now = Date.now();

        if (state.value.paidOrders.length) {
            const ordersToKeep = state.value.paidOrders.filter(x => x.expires.getTime() > now);
            const removedOrders = state.value.paidOrders.filter(x=>!ordersToKeep.includes(x));
            console.log('orderStore.removeExpiredOrders removed orders', removedOrders);
            //debugger;
            state.value.paidOrders = ordersToKeep;
        }

        if (state.value.deliveredOrders.length) {
            state.value.deliveredOrders = state.value.deliveredOrders.filter(x => x.expires.getTime() > now);
        }
        // console.log('removeExpiredOrders', state.value.paidOrders, state.value.deliveredOrders);
        save()
    }
    
    const addToPaidOrders = () => {
        console.log('addToPaidOrders', state.value.details?.orderId, state.value.details?.pickupTime, state.value.details);
        if (state.value.details?.orderId && state.value.details.pickupTime) {
            if (!state.value.paidOrders.find(x => x.id === state.value.details?.orderId)) {

                //If pickupTime is a string, convert it to Date
                if (typeof state.value.details.pickupTime === 'string') {
                    state.value.details.pickupTime = new Date(state.value.details.pickupTime);
                }

                const expires = state.value.details.pickupTime;

                expires.setMinutes(expires.getMinutes() + 60) // Set expires 60 minutes in the future

                state.value.paidOrders.push({
                    id: state.value.details.orderId,
                    expires: expires,
                    customerCode: state.value.details.customerCode
                });
                save();
            } else {
                console.log(`order ${state.value.details?.orderId} already exists in paidOrders`);
            }
        } else {
            console.log('order does not have a valid orderId or pickupTime');
        }
    }

    const removeOrderFromPending = () => {
        if (state.value.details?.orderId) {
            console.log(`removing order ${state.value.details?.orderId} from paidOrders`);
            const index = state.value.paidOrders.findIndex(x => x.id === state.value.details?.orderId);
            if (index !== -1) {
                state.value.paidOrders.splice(index, 1);
                save();
            }
        }
    }
    
    const isPaid = (orderId:string)=> {
        const index = state.value.paidOrders.findIndex(x => x.id === orderId);
        return index !== -1;
    }

    /* AbortController */
    const abortController = new AbortController();
    const getOrderStatus = () => {
        //validateOrders();
        removeExpiredOrders();
        
        if (state.value.paidOrders.length) {

            const order = state.value.paidOrders[0];

            axios.get<OrderStatus | ResponseError>(`/api/order/${order.id}`, {
                headers: {
                    'X-CustomerCode': ''
                },
                signal: abortController.signal
            })
              .then(response => response.data)
              .then((data: OrderStatus | ResponseError) => {

                  if (isResponseError(data)) {
                      console.error('Error -> getOrderStatus', data);
                  } else {

                      if (data.status && typeof data.status === 'string') {

                          // state.value.status = data.status;
                          switch (data.status) {
                              case OrderStatusState.CalculatedPrice:
                                  // Do someting
                                  break;
                              case OrderStatusState.Added:
                                  // Do someting
                                  break;
                              case OrderStatusState.Started:
                                  // Do someting
                                  break;
                              case OrderStatusState.Done:
                                  onDone(order);
                                  break;
                              case OrderStatusState.Delivered:
                                  onDelivered(order);
                                  break;
                          }
                      }
                  }

              }).catch((error) => {
                console.error('error', error);
            });
        }
        else {
            cancelPullOrderStatus();
        }
    }

    const onDelivered = (order: PaidOrder) => {
        // Remove order from array.
        if (order) {
            const index = state.value.paidOrders.findIndex(x => x.id === order.id);
            state.value.paidOrders.splice(index, 1);

            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 30) // Set expires 30 minutes in the future
            order.expires = expires;
            state.value.deliveredOrders.push(order);
        }

        if (!state.value.paidOrders.length) // If no order left then end status pulling
        {
            clearInterval(pullIntervalKey);
            pullIntervalKey = undefined;
        }
    }

    const onDone = (order: PaidOrder) => {
        if (order) {
            const index = state.value.paidOrders.findIndex(x => x.id === order.id);
            state.value.paidOrders.splice(index, 1);

            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 30) // Set expires 30 minutes in the future
            order.expires = expires;
            state.value.deliveredOrders.push(order);
        }

        if (!state.value.paidOrders.length) // If no order left then end status pulling
        {
            clearInterval(pullIntervalKey);
            pullIntervalKey = undefined;
        }
    }

    //loadFromStorage(null);
    //window.addEventListener('storage', loadFromStorage);

    return {
        save,
        clear,
        set,
        id,
        ordersCount,
        addPaymentID,
        state,
        getOrderStatus,
        pullOrderStatus,
        cancelPullOrderStatus,
        addToPaidOrders,
        isPaid,
        removeOrderFromPending,
        removeExpiredOrders
    }

})