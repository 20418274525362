export default {
  home: {
    header: 'Welcome!',
    subHeader: 'Choose your restaurant',
  },
  list: {
    menu: 'Menu'
  },
  shopchanged: {
    header: 'Warning!',
    text: 'You have a pending order linked to a different restaurant than the current one.'
  },
  checkout: {
    header: 'Checkout',
    pickup: 'Pickup time',
    orderHeader: 'My order',
  },
  payment: {
    header: 'Pay order',
    confirmLeave: {
      header: 'Confirm leave',
      text: 'Are you sure you want to leave the page? Leaving now may prevent us from updating you with the correct order status as your payment is still in progress.',
      confirm: 'Yes, I want to leave',
      cancel: 'No, I want to stay'
    }
  },
  termsAndConditions: {
    header: 'Terms and Conditions',
    closeButton: 'Close'
  },
  suggestedArticles: {
    header: 'May we offer something else?',
  },
  selectedShop: {
    header: 'Choose where to eat',
    extraInfo: ''
  },
  search: {
    label: 'Search restaurant',
    placeholder: 'Find restaurant',
    noMatch: 'No matching restaurants'
  },
  shopSelector: {
    singleShop: {
      start: "Start ordering",
      closed: "Closed for today",
      paused: "Ordering paused",
      offline: "Offline",
      openingHoursHeader: "Opening hours",
    }
  },
  'show-password': {
    show: 'Show password',
    hide: 'Hide password'
  },
  button: {
    showMore: 'Show more',
    showMoreRestaurants: 'Show more restaurants',
    open: 'Open',
    closed: 'Closed',
    offline: 'Offline',
    paused: 'Ordering paused',
    dineInPlace: 'At the restaurant',
    takeAway: 'Take away',
    pay: 'Pay',
    order: 'Order',
    orderNotReady: 'Order not ready',
    change: 'Change',
    noThanks: 'No thanks',
    done: 'Done',
    cancel: 'Cancel',
    changeRestaurant: 'Change restaurant',
    changeLanguage: 'Change language',
    cancelOrder: 'Cancel order',
    yes: 'Yes',
    no: 'No',
    add: 'Add',
    backToCategory: 'Back to {category}',
    backToCategories: 'Back to categories',
    backToHome: 'Back to {category}',
    backToArticle: 'Back to {article}',
    backToShopPage: 'Back',
    backToMenuRoot: 'Back',
    back: 'Back',
    receipt: 'Receipt',
    favorite: 'Favorite',
    choiceDone: 'Done',
  },
  item: {
    notAvailable: 'item out of stock',
    favoriteHeader: 'My favorites'
  },
  label: {
    total: 'Total',
    subtotal: 'Subtotal',
    tax: 'Tax ({percent}%)',
    articleChoiceLabel1: 'Change/add ingredients',
    articleChoiceLabel2: 'Change',
    articleChoiceLabel3: 'Choose extra accessories',
    articleChoiceLabel4: 'Change',
    articleChoiceLabel5: 'Choose flavor',
    dineInPlace: 'Eat here',
    takeAway: 'Take away',
    now: 'Now'
  },
  order: {
    header: 'My order',
    pageHeader: 'Your order',
    thankYouText: 'Thanks for your order',
    infoText: '(Tap on product to change)',
    infoText2: '(Discounts are deducted in the next step)',
    emptyInfoText: 'No items in the basket yet',
    selectedRestaurant: 'Selected restaurant',
    missing: 'Order is missing',
    pickUpLabel: 'Estimated pick up time',
    statusLabel: 'current status',
    numberLabel: 'Your order number',
    note: {
      label: "Other information",
      placeholder: "Enter other information",
      remove: "Remove"
    },
    deliverySpot: {
      label: "Delivery to",
      empty: "Not selected..."
    },
    status: {
      calculatedprice: {
        label: "Verifying order...",
        description: ""
      },
      added: {
        label: 'Your order is received',
        description: "Your order is received and is being processed."
      },
      started: {
        label: 'Your order is being prepared!',
        description: "Your order is being prepared."
      },
      finnished: {
        label: 'Ready for pickup!',
        description: "Your order is ready for pickup."
      },
      delivered: {
        label: "Delivered!",
        description: "We hope you enjoy your meal."
      },
      rollbacked: {
        label: "Avbruten",
        description: "Order cancelled, the reservation on your card has been removed, if you have payed with Swish, please contact the restaurant for a refund."
      }
    },
    campaignLabel: 'Campaign'
  },
  loader: {
    text: 'Loading...',
    languages: 'Looking for language options',
    categories: 'Looking for categories',
    favorites: 'Looking for favorites',
    orderHistory: 'Fetching your order history',
    image: '',
    calculatesDistances: 'Calculates distances',
    checkoutSummary: 'Contacting the restaurant',
  },
  cancel: {
    header: 'Cancel',
    text: 'Do you want to cancel?',
    order: {
      header: 'Cancel order',
      description: 'Do you want to cancel your order?',
      confirm: 'Yes, cancel',
      cancel: 'No, continue',
    }
  },
  error: {
    heading: 'Error message',
    closeButtonText: 'Close',
    server: 'There were a server error, please try again.',
    badRequest: 'Your browser sent a request that this server could not understand',
    unknown: 'Unknown error, please try again.',
    "One or more validation errors occurred": "One or more validation errors occurred.",
    InvalidToken: "Your password reset token is invalid or has expired, please reset password again.",
    PasswordRequiresNonAlphanumeric: "Password must have at least one non alphanumeric character.",
    PasswordRequiresDigit: "Password must have at least one digit ('0'-'9').",
    PasswordRequiresLower: "Password must have at least one lowercase ('a'-'z').",
    PasswordRequiresUpper: "Password must have at least one uppercase ('A'-'Z').",
    PasswordTooShort: "The password needs to be atleast 6 characters",
    PasswordMismatch: "Passwords do not match.",
    DuplicateUserName: "An account with this email already exists.",
    DuplicateEmail: "Try to login or forgot password insted.",
    deliverySpotRequired: "Please select a delivery spot before proceeding.",
    order: {
      "failed-find-order": "An order with id {orderId} could not be found. Please check if the sum has been withdrawn from your account.",
      "unavailable-products": "One or more of the products in your order is no longer available for sale.",
      "shop-closed": "The restaurant you are ordering from are no longer open, try again later.",
      "shop-closed-at-pickuptime": "The restaurant is closed at your specified pickup time",
      "shop-paused": "The restaurant you are ordering from are sadly not accepting any online orders right now, try again later.",
      "create-order-failed": "For some reason we could not create your order, please try again later.",
      "create-order-failed-missing-recipe-items": "Some of the products in your order is missing required ingredients.",
      "preorder-create-order-failed": {
        "invalid-shop-id": "Invalid shop ID, please check and try again.",
        "shop-not-found": "The restaurant you're trying to order from is either closed or has permanently closed. Please try again later.",
        "internal-server-error": "An unknown error occurred with the ordering system, please try again later.",
        "missing-parameter": "A required field is missing, please check your order and try again.",
        "invalid-parameter": "One or more invalid values were provided, please check and try again.",
        "shop-paused": "The restaurant you're trying to order from is currently not accepting online orders, please try again later.",
        "shop-offline": "The restaurant you're trying to order from is offline, please try again later.",
        "shop-method-not-supported": "The restaurant does not support this ordering method, please try another method.",
        "shop-error-internal-error": "An internal error occurred at the restaurant, please try again later.",
        "shop-error-invalid-date-time": "Invalid date or time specified, please check and try again.",
        "shop-error-date-time-in-the-past": "The specified date or time is in the past, please choose a later time.",
        "shop-error-timeout-waiting-for-call-lock": "Timeout occurred while waiting for a response, please try again.",
        "shop-error-terminal-not-found": "The restaurant's terminal could not be found, please try again later.",
        "shop-error-stock-center-not-found": "Stock center could not be found, please try again later.",
        "shop-error-recipe-items-missing": "Some ingredients for the order are missing, please contact the restaurant.",
        "shop-error-internal-error-in-call": "An internal error occurred while communicating with the restaurant, please try again later.",
        "shop-error-order-not-found": "The order could not be found, please check your order details.",
        "shop-error-order-is-delivered": "The order has already been delivered.",
        "shop-error-order-is-deleted": "The order has been deleted.",
        "shop-error-order-is-started": "The order has already started and cannot be modified.",
        "shop-error-database-error": "A database error occurred, please try again later.",
        "shop-error-failed-to-open-database": "Failed to open the database, please try again later.",
        "shop-error-failed-to-init-coffe-card": "Failed to initialize the coffee card, please try again later.",
        "shop-error-failed-to-init-coffe-card-campaing": "Failed to initialize the coffee card campaign, please try again later.",
        "shop-error-profit-center-not-found": "Profit center could not be found, please contact support.",
        "shop-error-payment-not-found": "Payment could not be found, please try again.",
        "shop-error-slots-not-in-use": "Time slots are not in use at the moment, please try again later.",
        "shop-error-cashier-not-found": "Cashier could not be found, please try again later.",
        "shop-error-currency-error": "A currency error occurred, please check and try again.",
        "shop-error-articles-not-available": "Some items are not available, please update your order.",
        "shop-error-slot-not-available": "The selected time slot is not available, please choose another.",
        "shop-error-internal-error-post-order-check-error": "An internal error occurred after the order, please try again.",
        "shop-error-internal-error-post-order-fixed": "An internal error occurred but it has been fixed, please proceed.",
        "shop-error-internal-error-post-order-not-fixed": "An internal error occurred and it could not be fixed, please try again later.",
        "shop-error-no-central-web-plu": "Central web PLU is missing, please contact support.",
        "unknown-error": "An unknown error occurred, please try again."
      }
    },
    payment: {
      failed: 'An unknown error occured at payment (code:01)',
      aborted: 'An unknown error occured at payment (code:02)',
      notPaid: 'An unknown error occured at payment (code:03)',
      invalid: 'An unknown error occured at payment (code:04)',
      accesstokenMissing: 'An unknown error occured at payment (code:05)',
      IdentifierMissing: 'An unknown error occured at payment (code:06)',
      NotFound: 'An unknown error occured at payment (code:07)',
      merchantNotFound: 'An unknown error occured at payment (code:08)',
      orderNotFound: 'An unknown error occured at payment (code:09)',
      returnUrl: 'An unknown error occured at payment (code:10)',
      requestParametersMissing: 'An unknown error occured at payment (code:11)',
      orderFailed: 'An unknown error occured at payment (code:12)',
      captureFailed: 'An unknown error occured at payment (code:13)',
      createError: 'An unknown error occured at payment (code: 14)',
      loadScriptError: 'An unknown error occured at payment (kod: 15)',
    },
    user: {
      "invalid-password": "The password provided does not meet the minimum requirements.",
      "register-user-failure": "Failed to create account",
      "password-mismatch": "Passwords do not match",
      "send-email-confirmation-failed": "Failed to send email confirmation",
      "invalid-phone": "Invalid phone number",
      "requires-phone": "Phone number is required"
    },
    menu: {
      "not-found": "The menu could not be found for shop with id {shopId}",
    }
  },
  privacyPolicy: {
    header: 'Privacy policy'
  },
  receipt: {
    heading: "Receipt",
    total: "Total",
    amountPrefix: "@",
    vat: "VAT",
    net: "Net",
    orderNumber: "OrderNr",
    paymentId: "Payment id",
    note: "Other information",
    deliverySpot: "Delivery to",
    email: "Email:",
    emailPlaceHolder: "Enter your email address",
    sendEmail: "Send receipt",
    loading: "Sending email...",
    emailSent: "Email sent",
    emailFailed: "Failed to send the receipt via email, please try again.",
    deliveryDate: "Delivery time",
  },
  article: {
    allergens: 'Allergic? Talk to our staff and we will help you.',
    maxChoices: 'Maximum {value} choices'
  },
  offline: {
    header: 'Offline',
    text: 'You are offline, please check your internet connection and try again.',
    button: 'Try again'
  },
  updateApp: {
    text: 'A new version is available, please update to continue.',
    button: 'Update'
  },
  login: {
    header: 'Sign in',
    email: 'E-mail',
    password: 'Password',
    registerText: "Don't have an account yet?",
    registerLink: 'Create new account',
    confirm: 'log in',
    forgotPassword: 'Forgot password',
    separatorText: 'Or',
    failed: 'Failed to login, please try again',
    googleSignIn: 'Sign in with Google',
    facebookSignIn: 'Sign in with Facebook'
  },
  forgotPassword: {
    header: 'Forgot password',
    text: 'Have you forgotten your password? Enter your email to receive a recovery email.',
    email: {
      label: 'E-mail',
      placeholder: 'E-mail'
    },
    send: {
      text: 'Send'
    },
    sent: {
      header: 'E-mail sent',
      message: 'A recovery email has been sent to the specified e-mail, if you have not received an e-mail within a few minutes, there is no account linked to the specified e-mail.',
      newAccountText: 'Create an account'
    },
  },
  resetPassword: {
    header: 'Reset password',
    password: {
      label: 'New password',
      placeholder: 'Enter new password'
    },
    confirmPassword: {
      label: 'Confirm new password',
      placeholder: 'Enter the new password again'
    },
    send: {
      text: 'Reset password'
    },
    reset: {
      header: 'Password reset',
      message: 'Your password has been reset, you can now log in with your new password.'
    }
  },
  campaign: {
    bannerText: 'Campaign'
  },
  memberCard: {
    header: 'My digital club-card',
    text: 'Scan me on the kiosk or at the pos'

  },
  favorites: {
    header: 'My favorites',
    empty: 'You have no favorites yet'
  },
  orderHistory: {
    header: 'Order history',
    empty: 'Your order history is empty'
  },
  register: {
    header: 'Create account',
    success: {
      header: 'Account created',
      text: 'An email has been sent to the specified email address, please check your inbox and follow the instructions to confirm your email address. After that is completed you can sign in.',
      loginLink: 'Sign in'
    },
    firstname: {
      label: 'First name',
      placeholder: 'Enter your first name'
    },
    lastname: {
      label: 'Last name',
      placeholder: 'Enter your last name'
    },
    email: {
      label: 'Email',
      placeholder: 'Enter email'
    },
    phone: {
      label: 'Phone',
      placeholder: 'Enter phone number'
    },
    password: {
      label: 'Password',
      placeholder: 'Enter password'
    },
    confirmPassword: {
      label: 'Repeat password',
      placeholder: 'Repeat the password'
    },
    acceptTerms: {
      label: 'I accept the ',
      linkText: 'terms and conditions'
    },
    registerButton: 'Create account',
    passwordMismatch: {
      text: 'The passwords do not match'
    },
  },
  confirmEmail: {
    header: "Confirm email",
    text: "Your account has been verified, you can now log in",
  },
  navBar: {
    login: 'Sign in'
  },
  campaigns: {
    header: 'Offers',
    description: 'Below you see all the offers that are active right now, if you are logged in there may be more offers',
    descriptionAuthenticated: 'Below you see all the offers that are active right now.',
    membersOnly: {
      text: 'Membership offer'
    },
    localOffer: {
      text: 'Local offer'
    },
    offer: {
      text: 'Offer'
    },
    toArticle: {
      text: 'To the offer'
    },
    expandButton: {
      text: "Show more"
    },
    showMoreButton: {
      text: "More offers"
    },
  },
  profile: {
    edit: {
      header: "Edit Profile",
      submit: {
        text: "Save",
        title: "Save the profile"
      },
      userInformation: {
        header: "User information",
        name: {
          label: "Name",
          placeholder: "Enter your name",
          description: "Your name will be displayed in the app"
        },
        lastName: {
          label: "Surname",
          placeholder: "Enter your surname",
          description: "Your name will be displayed in the app"
        },
        email: {
          label: "Email",
          placeholder: "Enter your Email",
          description: "Your email will be used to sign in to the app"
        },
        phone: {
          label: "Phone number",
          placeholder: "Enter your phone number",
          description: "Your phone number will be used to contact you if needed"
        },
      },
      changePassword: {
        header: "Change password",
        password: {
          label: "Current password",
          placeholder: "Enter your current password",
          description: "The password you currently use to sign in to the application"
        },
        newPassword: {
          label: "New password",
          placeholder: "Enter your new password",
          description: "The password you want to change to"
        },
        confirmNewPassword: {
          label: "Confirm the new password",
          placeholder: "Enter your new password again",
          description: "Confirm the password you want to change to"
        },
      },
      emailConfirmationSent: "An email has been sent to the specified email address, please check your inbox and follow the instructions to confirm your email address.",
      success: {
        header: "Saved!",
        text: "Your profile has been saved"
      },
      deleteAccount: {
        text: "Delete account",
        title: "Delete your account",
        confirm: {
          header: "Delete account?",
          description: "Are you really sure that you want to delete your account?",
          ok: "Yes, delete",
          cancel: "Cancel"
        }
      }
    },
    menu: {
      userGreeting: 'Hi!',
      offers: 'Offers',
      favorites: 'My favorites',
      orderHistory: 'Order history',
      account: 'My account',
      logout: 'Sign out'
    },

    "confirm-email": {
      header: "Confirm email",
      text: "Your account has been verified, you can now log in",
    }
  },
}